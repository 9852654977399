<template>
    <div>
        <Navbar page="Gestão de Cookies" link="/cookies" nameLink="Cookies" />

        <div class="mx-4 my-4 md:mx-8 md:my-8">

            <div v-if="form" class="grid grid-cols-12 gap-6 my-5">
                <!-- PREVIEW -->
                <div class="col-span-12 md:col-span-5">
                    <span class="w-96">Como ficará o banner na versão {{versao}}:</span>
                    <div class="flgpdmodal-cookies" :class="{
                        'flgpdmodal-cookies-left': form.template === 'flgpdmodal-cookies-left',
                        'flgpdmodal-cookies-right': form.template === 'flgpdmodal-cookies-right',
                        'flgpdmodal-cookies-bottom': form.template === 'flgpdmodal-cookies-bottom',
                        'flgpdmodal-cookies-center': form.template === 'flgpdmodal-cookies-center',
                    }" id="flgpdmodal-cookies-one">
                        <div class="flgpdmodal-cookies-container" :class="{
                                'flgpdmodal-largura-completa': form.template === 'flgpdmodal-cookies-bottom',
                                'flgpdmodal-largura-limitada': form.template !== 'flgpdmodal-cookies-bottom',
                            }"
                            :style="`border: 1px solid ${form.corFundo.hex};background: ${form.corFundo.hex};border-radius: ${form.arrendodamento}px`">
                            <button title='fechar modal dos cookies' type='button' id="flgpdmodal-cookies-exit"
                                class="flgpdmodal-close">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                    :fill="form.corCloseBanner.hex" viewBox="0 0 256 256">
                                    <path
                                        d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z">
                                    </path>
                                </svg>
                            </button>

                            <div class="flgdmodal-cookies-control" :style="`padding: ${form.expessura}px`">

                                <p id="flgdmodal-titulo" class="flgdmodal-titulo"
                                    :style="`color: ${form.corSubtitulos.hex}`">Você controla a sua privacidade</p>

                                <div id="flgdmodal-conteudo">
                                    <div>

                                        <p class="facillgpd-cookies-subtitle"
                                            :style="`color: ${form.corSubtitulos.hex}`">
                                            {{form.textInfoInicial}}
                                        </p>

                                        <p class="facillgpd-cookies-card">
                                            <a class="flgpd-link" target="_blank"
                                                :style="`color: ${form.corLinks.hex}`">
                                                <svg :fill="form.corLinks.hex" xmlns="http://www.w3.org/2000/svg"
                                                    width="16px" height="16px" viewBox="0 0 52 52"
                                                    enable-background="new 0 0 52 52" xml:space="preserve">
                                                    <path
                                                        d="M14,43.7V8.3c0-1,1.3-1.7,2.2-0.9l21.2,17.3c0.8,0.6,0.8,1.9,0,2.5L16.2,44.7C15.3,45.4,14,44.8,14,43.7z" />
                                                </svg>
                                                <span>
                                                    Política de privacidade
                                                </span>
                                            </a>
                                            <a class="flgpd-link" :href="`mailto:${form.emailContato}`"
                                                :style="`color: ${form.corLinks.hex}`">
                                                <svg :fill="form.corLinks.hex" xmlns="http://www.w3.org/2000/svg"
                                                    width="16px" height="16px" viewBox="0 0 52 52"
                                                    enable-background="new 0 0 52 52" xml:space="preserve">
                                                    <path
                                                        d="M14,43.7V8.3c0-1,1.3-1.7,2.2-0.9l21.2,17.3c0.8,0.6,0.8,1.9,0,2.5L16.2,44.7C15.3,45.4,14,44.8,14,43.7z" />
                                                </svg>
                                                <span>
                                                    {{form.emailContato ? form.emailContato : 'email@do.dpo'}}
                                                </span>
                                            </a>
                                        </p>
                                    </div>

                                    <div id="flgpd-botoes">
                                        <button
                                            :style="`color: ${form.corTextoCustomizar.hex};background-color: ${form.corCustomizar.hex};`"
                                            id="flgpd-personalizar" type="button"
                                            class="facilgpd-cookies-btn flgpd-full">Customizar</button>
                                        <button
                                            :style="`color: ${form.corTextoBotoes.hex};background-color: ${form.corBotoes.hex};`"
                                            id="flgpd-rejeitar" type="button"
                                            class="facilgpd-cookies-btn flgpd-full">Rejeitar</button>
                                        <button
                                            :style="`color: ${form.corTextoBotoes.hex};background-color: ${form.corBotoes.hex};`"
                                            id="flgpd-aceitar" type="button"
                                            class="facilgpd-cookies-btn flgpd-full">Aceitar</button>
                                    </div>
                                </div>

                                <div id="facillgpd-creditos" :style="`color: ${form.corSubtitulos.hex}`">
                                    <small class="facillgpd-cookies-small">
                                        Desenvolvido por
                                    </small>
                                    <a target="_blank" href="https://facillgpd.com.br">
                                        <img src='https://facillgpd.com.br/assets/logo_branca.png' />
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-span-12 md:col-span-7">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-6">
                            <h2 class="text-xl font-semibold mb-2">Informações sobre o site</h2>
                            <div class="bg-gray-100 px-2 py-5 rounded">
                                <h2 class="text-base font-semibold my-1">URL SITE: <span class="font-normal">{{ form.url }}</span></h2>
                                <h2 class="text-base font-semibold my-1">Acessos ao site nós últimos 7 dias: <span class="font-normal">{{ form.historicoAcessos.acessos7dias }}</span></h2>
                                <h2 class="text-base font-semibold my-1">Acessos ao site nós últimos 30 dias: <span class="font-normal">{{ form.historicoAcessos.acessos30dias }}</span></h2>
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-6">
                            <h2 class="text-xl font-semibold mb-2">Histórico acessos site <small>(últimos 20 dias)</small></h2>
                            <apexchart v-if="grafico.data.length" height="240" type="line" :options="grafico.option" :series="grafico.data"></apexchart>
                        </div>
                    </div>
                </div>

                <div class="col-span-12 w-full">
                    <span class="w-96">Como ficará a bolachinha:</span>
                    <a class="block" id="flgpdmodal-cookies-open" data-facilmodal="flgpdmodal-cookies-one"
                        :class="{'float-right': form.template === 'flgpdmodal-cookies-right' }"
                        :style="`background: ${form.corBolachinha.hex};position: relative; left: ${form.positionX}px; bottom: ${form.positionY}px`">
                        <svg xmlns="http://www.w3.org/2000/svg" :style="`height: 30px; color: ${form.corIcon.hex};`"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path d="M12 2a10 10 0 1 0 10 10 4 4 0 0 1-5-5 4 4 0 0 1-5-5"></path>
                            <path d="M8.5 8.5v.01"></path>
                            <path d="M16 15.5v.01"></path>
                            <path d="M12 12v.01"></path>
                            <path d="M11 17v.01"></path>
                            <path d="M7 14v.01"></path>
                        </svg>
                    </a>
                </div>

                <div class="col-span-12">
                    <h2 class="text-lg font-semibold mb-2">1 - Defina a posição do cookie banner na tela do computador
                    </h2>
                </div>

                <div class="col-span-12 md:col-span-6">
                    <label class="block text-base font-semibold my-1">Template:</label>

                    <div class="flex flex-row gap-6">
                        <label for="template_1" class="flex flex-col">
                            <img class="w-40 ring-1 ring-gray-600 rounded-md" src="/cookies-template/c-left.jpeg"
                                alt="">
                            <div>
                                <input type="radio" class="form-radio" for="template_1" name="template"
                                    v-model="form.template" value="flgpdmodal-cookies-left">
                                <span class="ml-1 md:ml-2 text-sm">Lateral Esquerda</span>
                            </div>
                        </label>

                        <label for="template_1" class="flex flex-col">
                            <img class="w-40 ring-1 ring-gray-600 rounded-md" src="/cookies-template/c-right.jpeg"
                                alt="">
                            <div>
                                <input type="radio" class="form-radio" for="template_1" name="template"
                                    v-model="form.template" value="flgpdmodal-cookies-right">
                                <span class="ml-1 md:ml-2 text-sm">Lateral Direita</span>
                            </div>
                        </label>

                        <label for="template_1" class="flex flex-col" :class="{'opacity-20': versao !== 'premium' }">
                            <img class="w-40 ring-1 ring-gray-600 rounded-md" src="/cookies-template/c-bottom.jpeg"
                                alt="">
                            <div>
                                <input :disabled="versao !== 'premium'" type="radio" class="form-radio" for="template_1" name="template"
                                    v-model="form.template" value="flgpdmodal-cookies-bottom">
                                <span class="ml-1 md:ml-2 text-sm">Rodapé</span>
                            </div>
                        </label>

                        <label for="template_1" class="flex flex-col" :class="{'opacity-20': versao !== 'premium' }">
                            <img class="w-40 ring-1 ring-gray-600 rounded-md" src="/cookies-template/c-center.jpeg"
                                alt="">
                            <div>
                                <input :disabled="versao !== 'premium'" type="radio" class="form-radio" for="template_1" name="template"
                                    v-model="form.template" value="flgpdmodal-cookies-center">
                                <span class="ml-1 md:ml-2 text-sm">Central</span>
                            </div>
                        </label>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-3" :class="{'opacity-20': versao !== 'premium' }">
                    <div class="flex flex-col">
                        <label for="vol">Expessura da borda</label>
                        <div>
                            <input :disabled="versao !== 'premium'" v-model="form.expessura" type="range" id="vol" name="vol" min="0" max="50">
                            <input  :value="form.expessura" class="w-12 h-8 rounded-md ml-1" type="text" disabled>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <label for="vol">Arrendondamento dos cantos</label>
                        <div>
                            <input :disabled="versao !== 'premium'" v-model="form.arrendodamento" type="range" id="vol" name="vol" min="0" max="50">
                            <input  :value="form.arrendodamento" class="w-12 h-8 rounded-md ml-1" type="text" disabled>
                        </div>
                    </div>

                    <h5 class="font-bold mt-8">Use as opções abaixo quando você notar que a bolachinha sobrepos algum elemento importante do seu site</h5>
                    <div class="flex flex-col">
                        <label for="vol">Mover a bolachinha para cima ou abaixo em pixels</label>
                        <div>
                            <input :disabled="versao !== 'premium'" v-model="form.positionY" type="range" id="vol" name="vol" min="-50" max="50">
                            <input  :value="form.positionY" class="w-14 h-8 rounded-md ml-1" type="text" disabled>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <label for="vol">Mover a bolachinha para esquerda ou direita em pixels</label>
                        <div>
                            <input :disabled="versao !== 'premium'" v-model="form.positionX" type="range" id="vol" name="vol" min="-50" max="50">
                            <input  :value="form.positionX" class="w-14 h-8 rounded-md ml-1" type="text" disabled>
                        </div>
                    </div>
                </div>

                <div class="col-span-12">
                    <h2 class="text-lg font-semibold mb-2">2 - Defina as cores</h2>
                </div>

                <div class="col-span-12 flex flex-row gap-3 flex-wrap flex-1">
                    <div class="flex flex-col">
                        <label class="block text-sm font-medium mb-2">Cor fundo</label>
                        <sketch-picker v-model="form.corFundo" />
                    </div>

                    <div class="flex flex-col">
                        <label class="block text-sm font-medium mb-2">Cor do texto principal </label>
                        <sketch-picker v-model="form.corSubtitulos" />
                    </div>

                    <div class="flex flex-col">
                        <label class="block text-sm font-medium mb-2">Cor dos links</label>
                        <sketch-picker v-model="form.corLinks" />
                    </div>

                    <div class="flex flex-col">
                        <label class="block text-sm font-medium mb-2">Cor do botão customizar</label>
                        <sketch-picker v-model="form.corCustomizar" />
                    </div>

                    <div class="flex flex-col" :class="{'opacity-20': versao !== 'premium' }">
                        <label class="block text-sm font-medium mb-2">Cor dos demais botões</label>
                        <sketch-picker v-if="versao === 'premium'" v-model="form.corBotoes" />
                        <sketch-picker v-if="versao !== 'premium'" :value="form.corBotoes" @input="doUpgrade" :disableFields="true" :disableAlpha="true" />
                    </div>

                    <div class="flex flex-col" :class="{'opacity-20': versao !== 'premium' }">
                        <label class="block text-sm font-medium mb-2">Cor do texto dos botões</label>
                        <sketch-picker v-if="versao === 'premium'" v-model="form.corTextoBotoes" />
                        <sketch-picker v-if="versao !== 'premium'" :value="form.corTextoBotoes" @input="doUpgrade" :disableFields="true" :disableAlpha="true" />
                    </div>

                    <div class="flex flex-col" :class="{'opacity-20': versao !== 'premium' }">
                        <label class="block text-sm font-medium mb-2">Cor do texto customizar</label>
                        <sketch-picker v-if="versao === 'premium'" v-model="form.corTextoCustomizar" />
                        <sketch-picker v-if="versao !== 'premium'" :value="form.corTextoCustomizar" @input="doUpgrade" :disableFields="true" :disableAlpha="true" />
                    </div>

                    <div class="flex flex-col" :class="{'opacity-20': versao !== 'premium' }">
                        <label class="block text-sm font-medium mb-2">Cor da bolachinha (cookie)</label>
                        <sketch-picker v-if="versao === 'premium'" v-model="form.corBolachinha" />
                        <sketch-picker v-if="versao !== 'premium'" :value="form.corBolachinha" @input="doUpgrade" :disableFields="true" :disableAlpha="true" />
                    </div>

                    <div class="flex flex-col" :class="{'opacity-20': versao !== 'premium' }">
                        <label class="block text-sm font-medium mb-2">Cor do ícone da bolachinha</label>
                        <sketch-picker v-if="versao === 'premium'" v-model="form.corIcon" />
                        <sketch-picker v-if="versao !== 'premium'" :value="form.corIcon" @input="doUpgrade" :disableFields="true" :disableAlpha="true" />
                    </div>

                    <div class="flex flex-col" :class="{'opacity-20': versao !== 'premium' }">
                        <label class="block text-sm font-medium mb-2">Cor do 'X' de fechar </label>
                        <sketch-picker v-if="versao === 'premium'" v-model="form.corCloseBanner" />
                        <sketch-picker v-if="versao !== 'premium'" :value="form.corCloseBanner" @input="doUpgrade" :disableFields="true" :disableAlpha="true" />
                    </div>
                </div>


                <div class="col-span-12">
                    <h2 class="text-lg font-semibold mb-2">3 - Defina o conteúdo informativo do cookie</h2>
                </div>

                <div class="col-span-12 gap-3">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-4" :class="{'opacity-20': versao !== 'premium' }">
                            <label for="fontfamily" class="block text-sm font-medium mb-2">Fonte (Deve ser um nome de fonte presente no seu site)</label>
                            <input :disabled="versao !== 'premium'" type="text" v-model="form.fontfamily" name="fontfamily" id="fontfamily" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                        </div>

                        <div class="col-span-4">
                            <label for="textInfoInicial" class="block text-sm font-medium mb-2">Texto inicial <small
                                    class="text-red-600 ml-1">({{form.textInfoInicial.length}} / 200 caracteres
                               id="textInfoInicial"
                                     ideais)</small></label>
                            <textarea rows="5" type="text" v-model="form.textInfoInicial" name="textInfoInicial"
                                class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                        </div>

                        <div class="col-span-4">
                            <label for="tituloBanner" class="block text-sm font-medium mb-2">Titulo que aparece no topo do
                                cookie banner</label>
                            <input type="text" v-model="form.tituloBanner" name="tituloBanner" id="tituloBanner"
                                class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl" />
    
                            <label class="block text-sm font-medium mb-2 mt-4">Insira o E-mail do dpo que aparecerá no
                                cookie banner</label>
                            <input type="email" v-model="form.emailContato" name="emailContato" id="emailContato"
                                class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full h-9 shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>

                        <div class="col-span-4">
                            <h2 class="block text-sm font-medium mb-2 w-96">Insira sua política de privacidade em pdf (ao
                                clicar no
                                link o visitante do site irá acessá-la em pdf)</h2>
                            <vue-dropzone ref="doc" id="doc" class="w-96 mt-2" :options="dropzoneOptions"
                                v-on:vdropzone-success="update">
                            </vue-dropzone>
                            <a v-if="this.form && this.form.politica_privacidade && this.form.politica_privacidade.filename"
                                :href="`${this.url_api}/upload?mimetype=${this.form.politica_privacidade.mimetype}&filename=${this.form.politica_privacidade.filename}&folder=empresas`"
                                target="_blank" rel="noreferrer" class="underline">
                                <br />
                                <span class="font-semibold">Arquivo:</span>
                                {{this.form.politica_privacidade.originalname}}
                            </a>
                            <a v-if="this.form && this.form.politica_privacidade && this.form.politica_privacidade.filename"
                                @click="form.politica_privacidade = { folder: '' }">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mt-1 mr-2" fill="none"
                                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </a>
                        </div>

                        <div class="col-span-4">
                            <h2 class="block text-sm font-medium mb-2">Link para Política de Privacidade</h2>
                            <input type="text" v-model="form.politica_privacidade_link"
                                class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-96 h-9 shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>

                        <div class="col-span-4 flex flex-col gap-2">
                            <label for="ocultarAposPrimeiroAcesso">Aparecer somente no primeiro acesso</label>
                            <input class="h-6 w-6" type="checkbox" id="ocultarAposPrimeiroAcesso" v-model="form.ocultarAposPrimeiroAcesso">
                        </div>
                    </div>
                </div>

            </div>


            <div v-if="form" class="grid grid-cols-12 gap-6 my-5">

            </div>
            <div class="grid grid-cols-2 mt-5">
                <div class="py-3 text-left">
                    <button @click="$router.back()" type="button"
                        class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                </div>
                <div class="py-3 text-right">
                    <button @click="save" type="button"
                        class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400"
                        :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vueDropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import {
        Chrome
    } from 'vue-color';

    export default {
        components: {
            vueDropzone,
            'sketch-picker': Chrome,
        },
        data() {
            return {
                versao: this.$store.state.empresa && this.$store.state.user.cookieBanner ? this.$store.state.user.stripe.plano : 'premium',
                route: 'cookies',
                form: null,
                dropzoneOptions: this.$http.getDropzoneConfig(
                    `v1/upload`,
                    'post', {
                        maxFiles: 1,
                        addRemoveLinks: true,
                        capture: true,
                        dictDefaultMessage: 'Clique ou arraste a Política de Privacidade aqui',
                        dictRemoveFile: 'Remover'
                }),
                grafico: {
                data: [],
                option: {
                    chart: {
                        type: "line",
                    },
                    xaxis: {
                        categories: [],
                    }
                }
            }
            }
        },
        methods: {
            doUpgrade() {
                this.$vToastify.error("Faça upgrade para a versão PRO para ter acesso a customização completa.", "Ops!");
            },
            async save() {

                this.form.corFundo = this.form.corFundo.hex ? this.form.corFundo.hex : this.form.corFundo;
                this.form.corSubtitulos = this.form.corSubtitulos.hex ? this.form.corSubtitulos.hex : this.form
                    .corSubtitulos;
                this.form.corLinks = this.form.corLinks.hex ? this.form.corLinks.hex : this.form.corLinks;
                this.form.corBotoes = this.form.corBotoes.hex ? this.form.corBotoes.hex : this.form.corBotoes;
                this.form.corCustomizar = this.form.corCustomizar.hex ? this.form.corCustomizar.hex : this.form
                    .corCustomizar;
                this.form.corTextoBotoes = this.form.corTextoBotoes.hex ? this.form.corTextoBotoes.hex : this.form
                    .corTextoBotoes;
                this.form.corTextoCustomizar = this.form.corTextoCustomizar.hex ? this.form.corTextoCustomizar.hex :
                    this.form.corTextoCustomizar;
                this.form.corBolachinha = this.form.corBolachinha.hex ? this.form.corBolachinha.hex : this.form
                    .corBolachinha;
                this.form.corIcon = this.form.corIcon.hex ? this.form.corIcon.hex : this.form.corIcon;
                this.form.corCloseBanner = this.form.corCloseBanner.hex ? this.form.corCloseBanner.hex : this.form
                    .corCloseBanner;

                const req = await this.$http.put(`/v1/${this.route}/`, this.form);
                if (req.data.success) {
                    this.$vToastify.success("Salvo com sucesso!");
                } else {
                    this.$vToastify.error(req.data.err);
                }
            },
            update(file, response) {
                this.form.politica_privacidade = response.file;
            },
        },
        async beforeMount() {
            const id = this.$route.params.id;

            if (id) {
                const req = await this.$http.get(`/v1/${this.route}/${id}`);
                this.form = req.data.data;

                this.form.historico = req.data.historico;
                this.form.historicoAcessos = req.data.historicoAcessos;

                const reqGrafico = await this.$http.get(`/v1/${this.route}/grafico-acesso/${id}`);

                this.grafico.data = [{ name: 'Acessos', data: reqGrafico.data.acessosData }];
                this.grafico.option = this.grafico.option = {
                    chart: {
                        type: "line",
                    },
                    xaxis: {
                        categories: reqGrafico.data.labels,
                    },
                };

                this.form.corFundo = {
                    hex: this.form.corFundo
                };
                this.form.corSubtitulos = {
                    hex: this.form.corSubtitulos
                };
                this.form.corLinks = {
                    hex: this.form.corLinks
                };
                this.form.corBotoes = {
                    hex: this.form.corBotoes
                };
                this.form.corCustomizar = {
                    hex: this.form.corCustomizar
                };
                this.form.corTextoBotoes = {
                    hex: this.form.corTextoBotoes
                };
                this.form.corTextoCustomizar = {
                    hex: this.form.corTextoCustomizar
                };
                this.form.corBolachinha = {
                    hex: this.form.corBolachinha
                };
                this.form.corIcon = {
                    hex: this.form.corIcon
                };
                this.form.corCloseBanner = {
                    hex: this.form.corCloseBanner
                };

                this.versao = this.$store.state.empresa && this.$store.state.user.cookieBanner ? this.$store.state.user.stripe.plano : 'premium';
            }
        },
    }
</script>

<style>
    .facillgpd-cookies-card {
        font-size: 12px;
    }

    #flgpd-btns-interno {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 14px;
        margin: 0 10px;
    }

    .flgpd-obrigatorios {
        opacity: 0.6;
    }

    #flgpd-checks {
        display: none;
        flex-direction: column;
    }

    .flgpd-link {
        display: flex;
    }

    .flgpd-link span {
        margin-left: 10px;
    }

    .flgpd-full {
        width: 100%;
    }

    #flgdmodal-conteudo {
        display: flex;
    }

    #facillgpd-creditos img {
        height: 20px;
    }

    #facillgpd-creditos {
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    .flgdmodal-titulo {
        text-align: center;
        color: white;
        font-weight: bold;
        margin-bottom: 6px;
        margin-top: 6px;
        margin-right: 50px;
        margin-left: 20px;
    }

    #flgpdmodal-cookies-open {
        z-index: 999;
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 40px;
        font-size: 12px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        border: none;
        color: white;
        height: 30px;
        width: 20px;
        box-sizing: content-box;
        margin-bottom: 50px;
        margin-top: 50px;
        margin-left: 50px;
    }

    .flgpdmodal-cookies {
        display: flex;
        align-items: center;
        margin-bottom: 150px;
    }

    .flgpdopen-cookies {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
        z-index: 99;
        margin: 1px
    }

    .flgpdmodal-cookies-container {
        position: relative;
        display: flex;
        flex-direction: row;
        overflow: auto;
    }

    .flgdmodal-cookies-control {
        width: 100%;
    }

    .facillgpd-cookies-small {
        font-size: 8px !important;
        line-height: 1.3 !important;
    }

    .facillgpd-cookies-subtitle {
        font-size: 10px !important;
        line-height: 1.2 !important;
        margin-top: 5px !important;
        margin-bottom: 13px !important;
        text-align: justify;
        margin-left: 4px;
        margin-right: 12px;
    }

    .facilgpd-cookies-btn {
        padding: 5px 5px;
        margin-top: 10px !important;
        font-size: 12px !important;
        border-radius: 5px;
        display: block;
        outline: none;
        border: none;
    }

    .facilg-switch {
        position: relative;
        display: inline-block;
        width: 41px;
        height: 20px;
    }

    .facilg-switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }


    .flgpdmodal-close {
        position: absolute;
        right: 18px;
        top: 12px;
        outline: none;
        appearance: none;
        background: none;
        border: 0px;
        font-weight: bold;
        cursor: pointer;
    }

    .flgpdmodal-cookies-left {
        bottom: 50px;
        justify-content: left;
        left: 72px;
    }

    .flgpdmodal-cookies-right {
        bottom: 50px;
        justify-content: right;
        right: 72px;
    }

    .flgpdmodal-cookies-center {
        bottom: 50%;
        justify-content: center;
    }

    .flgpdmodal-cookies-bottom {
        bottom: 0px;
        width: 98%;
        left: 0;
        margin: 2px 10px;
        z-index: 9999;
    }

    .flgpdmodal-largura-completa {
        width: 100%;
    }

    .flgpdmodal-largura-limitada {
        max-width: 450px;
    }

    .flgpdmodal-largura-completa #flgpd-botoes {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 40px;
        width: 300px;
        margin: 0 auto;
    }

    .flgpdmodal-largura-completa #flgpd-botoes button {
        width: 90px;
        padding: 0 6px;
        margin: 0 4px;
    }

    .flgpdmodal-largura-completa .facillgpd-cookies-subtitle {
        width: 90%;
    }

    .flgpdmodal-largura-completa .facillgpd-cookies-card {
        display: flex;
    }

    .flgpdmodal-largura-completa .facillgpd-cookies-card a {
        margin-right: 10px;
    }

    .flgpdmodal-largura-completa .flgdmodal-cookies-control {
        padding: 4px;
    }

    .flgpdmodal-largura-completa .flgdmodal-titulo {
        margin-bottom: 4px;
        margin-top: 2px;
    }
</style>